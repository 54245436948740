import { Trans } from 'gatsby-plugin-react-i18next';
import React from 'react';

import OvalIllustration from '../../../../static/images/illustration-oval.inline.svg';

import FullwidthIllustration from '../volcaneers/FullwidthIllustration';
import ContactForm from './ContactForm';

const ContactSection = () => {
  return (
    <>
      <div className="container-slim">
        <div className="max-w-[650px] mx-auto">
          <section id="contact-section" className="w-full relative">
            <div className="pt-32 sm:pt-40 md:pt-64 lg:pt-64 text-center relative">
              <h1 className="font-heading text-32 md:text-48 text-volcaneers-black font-bold">
                <Trans>Contact us</Trans>
              </h1>
              <h2 className="mt-16 text-18 leading-7 text-volcaneers-black">
                <Trans>Interested in using Paima Engine for your game?</Trans>
                <br />
                <Trans>Want to get in contact with our team?</Trans>
                <br />
                <br />
                <Trans>Use the form below or email us at</Trans>{' '}
                <a
                  href="mailto:contact@paimastudios.com"
                  className="text-volcaneers-primary-default hover:underline hover:cursor-pointer"
                >
                  contact@paimastudios.com
                </a>
              </h2>
            </div>
          </section>
          <ContactForm />
        </div>
      </div>
      <FullwidthIllustration illustration={<OvalIllustration />} containerClasses="pt-[10vw] fill-volcaneers-black" />
    </>
  );
};

export default ContactSection;
