import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';

import CookieConsentBanner from '../components/CookieConsentBanner';
import ContactSection from '../components/v2/contact/ContactSection';
import Footer from '../components/v2/Footer';
import Header from '../components/v2/header/Header';
import Layout from '../components/v2/Layout';

const ContactPage = () => {
  const { t } = useTranslation();

  return (
    <Layout title={t('Contact us')}>
      <Header showComingSoonCountdown={false} redirectToBuyNft />
      <CookieConsentBanner />
      <ContactSection />
      <Footer />
    </Layout>
  );
};

export default ContactPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
