import classNames from 'classnames';
import React from 'react';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  classes?: string;
}

const Input = ({ type = 'text', classes, ...props }: InputProps) => {
  return (
    <input
      className={classNames(
        'w-full',
        'px-25',
        'py-20',
        'text-18',
        'bg-volcaneers-input-default',
        'outline-none',
        'transition',
        'focus:bg-volcaneers-input-dark',
        'focus:text-black',
        'rounded-full',
        classes
      )}
      type={type}
      {...props}
    />
  );
};

export default Input;
