import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import * as React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { getLocalizedLink } from '../../../utils/locale';

import Input from '../Input';
import Loader from '../Loader';
import Checkbox from '../volcaneers/Checkbox';
import VolcaneersButton from '../volcaneers/VolcaneersButton';

const ContactForm = () => {
  const { t, i18n } = useTranslation();

  const recaptchaRef = React.useRef<any>(null);
  const [isSubmitEnabled, setIsSubmitEnabled] = React.useState(false);
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [isSuccess, setSuccess] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [consent, setConsent] = React.useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    if (!isSubmitEnabled || !consent) {
      return;
    }

    const captchaToken = await recaptchaRef.current.getValue();

    const bodyObj = {
      email: name + ' <' + email + '>',
      message: message
    };

    try {
      setLoading(true);
      setSuccess(false);
      await fetch('https://contact-service.paimastudios.com', {
        headers: {
          'Content-Type': 'application/json'
        },
        mode: 'no-cors', // 'cors' by default
        method: 'POST',
        body: JSON.stringify({
          ...bodyObj,
          'captcha-token': captchaToken
        })
      });
      setSuccess(true);
      setName('');
      setEmail('');
      setMessage('');
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
      recaptchaRef.current.reset();
    }
  };

  return (
    <section id="contact-form">
      <div className="container-slim">
        <div className="pb-64 font-base">
          <div className="mt-40">
            <form name="contact" method="post" onSubmit={handleSubmit}>
              <input type="hidden" name="form-name" value="contact" />
              <div className="flex flex-col md:flex-row w-full">
                <div className="mb-20 md:mr-25 md:mb-0 w-full">
                  <Input
                    name="name"
                    id="name"
                    placeholder={t('Your full name *')}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </div>
                <div className="w-full">
                  <Input
                    type="email"
                    name="mail"
                    id="mail"
                    placeholder={t('Your email address *')}
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <textarea
                name="message"
                rows={5}
                className="mt-20 md:mt-25 px-25 py-20 text-18 w-full bg-volcaneers-input-default outline-none transition focus:bg-volcaneers-input-dark focus:text-black rounded-3xl"
                placeholder={t('Message *')}
                required
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
              <div className="g-recaptcha flex md:justify-start">
                <ReCAPTCHA
                  className="block"
                  ref={recaptchaRef}
                  sitekey={'6LdmiigiAAAAAAs82WR12TA94v7tyF9G7haTkTn-'}
                  onChange={() => setIsSubmitEnabled(true)}
                  onExpired={() => setIsSubmitEnabled(false)}
                  onErrored={() => setIsSubmitEnabled(false)}
                />
              </div>
              <div className="mt-16 lg:mt-24 flex">
                <Checkbox
                  classes="bg-white mr-0 flex items-center justify-center rounded-[4px]"
                  onClick={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setConsent(e.target.checked);
                  }}
                />
                <div className="-mt-1 text-14 leading-5 flex-1 text-black ml-8 pt-2">
                  <Trans
                    i18nKey="I have read, understand and consent to the Terms Of Service and the Privacy Policy."
                    components={[
                      <a
                        href={getLocalizedLink('/terms-of-service', i18n.language)}
                        className="underline"
                        target="_blank"
                      />,
                      <a
                        className="underline"
                        href={getLocalizedLink('/privacy-policy', i18n.language)}
                        target="_blank"
                      />
                    ]}
                  />
                </div>
              </div>
              <VolcaneersButton
                className="w-full block mt-24"
                sizeVariant="small"
                uppercaseText={false}
                disabled={!isSubmitEnabled || isLoading || !consent}
                type="submit"
              >
                {isLoading ? <Loader /> : <Trans>Send</Trans>}
              </VolcaneersButton>
            </form>
            {isSuccess && (
              <div className="flex items-start mt-24">
                <img src="/images/check-icon.svg" className="h-20 w-20" alt="" />
                <p className="text-primary-color-900 font-base text-14 pl-6">
                  <Trans>Thank you! We've received your message. Someone from out team will contact you soon.</Trans>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
